import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Categories = ({ categoryId, categories, onSelect }) => {
  return (
    <Tab.Group
      as="div"
      className="lg:flex lg:flex-1 lg:items-center justify-center overflow-x-auto"
      onChange={(index) => {
        const categoryId = categories[index].id;
        onSelect(categoryId);
      }}
    >
      <Tab.List
        className="flex space-x-8 overflow-scroll no-scrollbar"
        style={{ scrollbarWidth: 'none' }}
      >
        {categories.map((category) => (
          <Tab
            key={category.id}
            className={() =>
              classNames(
                categoryId === category.id
                  ? 'text-indigo-600 border-indigo-600'
                  : 'text-gray-900 border-transparent',
                'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium',
              )
            }
          >
            {category.name}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};

Categories.propTypes = {
  categoryId: PropTypes.string,
  categories: PropTypes.array,
  onSelect: PropTypes.func,
};

export default Categories;
