import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ItemWrapper, Item } from './../components/catalog';
import Categories from './../components/Categories';
import useSearch from './../hooks/useSearch';
import useCatalog from './../hooks/useCatalog';
import useStore from './../hooks/useStore';
import { useCheckout } from '../hooks/useCheckout';
import useIsLargeScreen from './../hooks/useIsLargeScreen';
import { useRouter } from 'next/router';

import ItemSelection from '../components/catalog/Item/ItemSelection';
import Header from './../components/catalog/Header';
import CartContainer from '../components/Cart/CartContainer';
import Layout from '../components/Layout';
import MobileFooter from '../components/catalog/MobileFooter';
import CartButton from '../components/CartButton';
import { getUniqueNameFromUrl } from '../components/helpers';
import EmptyCategory from '../components/EmptyCategory';
import NotFoundCatalog from '../components/NotFoundCatalog';
import Searchbar from './../components/Searchbar';
import { useModal } from '../components/Modal';
import Sort from '../components/common/Sort';

const FrontPage = ({
  data = { products: [], store: null, categories: [] },
}) => {
  const [categoryId, setCategory] = React.useState(null);
  const { text, isSearching, setSearching, onHandleSearch, onCloseSearch } =
    useSearch();
  const { store } = useStore();
  const allowOnlineOrders = store?.catalog?.allowOnlineOrders;
  const showOutOfStockItems = store?.catalog?.showOutOfStockItems;
  const { addItem, checkout, isLoading, availabilityIndex } = useCheckout();
  const { items, categories, onSortChange } = useCatalog({
    data,
    textQuery: text,
    categoryId,
    storeId: store?.id,
    showOutOfStockItems,
    availabilityIndex,
  });

  const router = useRouter();
  const { openModal, closeModal } = useModal();
  let isLargeScreen = useIsLargeScreen();
  const itemId = router.query.itemId;

  useEffect(() => {
    if (items.length > 0 && itemId) {
      const item = items.find((item) => item.id === itemId);
      item && onItemClick(item);
    }
  }, [itemId, items.length]);

  useEffect(() => {
    if (checkout.paymentOrderStatus === 'paid') {
      router.push('checkout');
    }
  }, [checkout.paymentOrderStatus]);

  const onItemClick = (item) => {
    openModal(
      null,
      <ItemSelection
        product={item}
        availabilityIndex={availabilityIndex}
        add={add}
      />,
      {},
      () => {
        shallowNavigate('/');
      },
    );
    shallowNavigate(`/?itemId=${item?.id}`);
  };

  const shallowNavigate = (path) => {
    router.push(path, undefined, { shallow: true });
  };

  const add = (selectedItem) => {
    closeModal();
    addItem(selectedItem);
  };

  const onItemAddClick = (item) => {
    if (item.variations.length > 1) {
      onItemClick(item);
    } else {
      const selectedVariation = item.variations[0];
      add({
        id: selectedVariation.id,
        variationId: selectedVariation.id,
        productId: item.id,
        name: item.name + ' ' + selectedVariation.name,
        price: selectedVariation.price,
        format: item.format,
        trackQty: Boolean(selectedVariation.trackQty),
        ...(selectedVariation.thumbnail
          ? { thumbnail: selectedVariation.thumbnail }
          : {}),
        ...(selectedVariation.costPrice
          ? {
              costPrice: selectedVariation.costPrice,
            }
          : {}),
        quantity: 1,
      });
    }
  };

  const goToCart = () => {
    openModal('', <CartContainer isModal />);
  };

  if (!data.store) {
    return <NotFoundCatalog />;
  }

  return (
    <Layout
      isIndex
      isLoading={isLoading}
      shouldEnableAds={data.shouldEnableAds}
      isLargeScreen={isLargeScreen}
      Floating={<CartContainer isLargeScreen={isLargeScreen} />}
      FloatingBar={
        <MobileFooter
          allowOnlineOrders={allowOnlineOrders}
          ConfirmButton={<CartButton goToCart={goToCart} />}
        />
      }
    >
      <header className="px-4 pt-8 lg:py-3">
        <Header store={store} />

        <div className="flex items-center mt-8">
          {categories.length > 1 && !isSearching && (
            <Categories
              categoryId={categoryId}
              categories={categories}
              onSelect={setCategory}
            />
          )}
          <div
            className={`flex ${
              isSearching && 'flex-1'
            } flex-row justify-end items-center ${
              isSearching && 'col-span-full'
            }`}
          >
            <Searchbar
              searchText={text}
              isSearching={isSearching}
              setSearching={setSearching}
              onHandleSearch={onHandleSearch}
              onCloseSearch={onCloseSearch}
            />
          </div>
        </div>
      </header>
      {items.length === 0 ? (
        <EmptyCategory />
      ) : (
        <>
          <div className="px-4 py-2 lg:p-4 flex justify-end">
            <Sort onSortChange={onSortChange} />
          </div>
          <ItemWrapper>
            {items.map((item) => (
              <Item
                key={item.id}
                item={item}
                availabilityIndex={availabilityIndex}
                onItemAddClick={onItemAddClick}
                onItemClick={onItemClick}
              />
            ))}
          </ItemWrapper>
        </>
      )}
    </Layout>
  );
};

FrontPage.propTypes = {
  data: PropTypes.object,
};

const getHostname = (req) => {
  return req ? req.headers.host : window.location.hostname;
};

FrontPage.getInitialProps = async ({ req }) => {
  // eslint-disable-next-line no-undef
  if (process.browser && __NEXT_DATA__.props.pageProps.data?.products) {
    // eslint-disable-next-line no-undef
    return __NEXT_DATA__.props.pageProps;
  }
  const hostname = getHostname(req, process.browser);
  const uniqueName = getUniqueNameFromUrl(hostname);
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api/catalog/${uniqueName}`,
  );
  const data = await res.json();
  return { data };
};

export default FrontPage;
